/**
 * Created by minimus on 24.05.2017.
 */
(function () {
  document.addEventListener('DOMContentLoaded', () => {
    const cssOpts = stbUserOptions.cssOptions;
    const tools = document.querySelectorAll('.stb-tool');
    Array.from(tools).forEach(e => e.addEventListener('click', event => {
      const target = event.currentTarget;
      const cnt = target.parentNode.parentNode;
      const body = cnt.childNodes[1];
      const img = target.childNodes[0];
      if (cnt.classList.contains('stb-visible') || !cnt.classList.contains('stb-hidden')) {
        cnt.classList.remove('stb-visible');
        if (!!window.Velocity) Velocity(body, 'slideUp', 500, () => {
          cnt.classList.add('stb-hidden');
          img.src = cssOpts.imgShow;
        });
        else if (!!jQuery) jQuery(body).velocity('slideUp', 500, () => {
          cnt.classList.add('stb-hidden');
          img.src = cssOpts.imgShow;
        });

      }
      else if (cnt.classList.contains('stb-hidden')) {
        cnt.classList.remove('stb-hidden');
        if (!!window.Velocity) Velocity(body, 'slideDown', 500, () => {
          cnt.classList.add('stb-visible');
          img.src = cssOpts.imgHide;
        });
        else if (!!jQuery) jQuery(body).velocity('slideDown', 500, () => {
          cnt.classList.add('stb-visible');
          img.src = cssOpts.imgHide;
        });
      }
    }));
  })
})();

